import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';

class AvgPriceChart extends Component {
  constructor(props) {
    super(props);

    this.chartColors = {
      red: 'rgb(255, 99, 132)',
      orange: 'rgb(255, 159, 64)',
      yellow: 'rgb(255, 205, 86)',
      green: 'rgb(75, 192, 192)',
      blue: 'rgb(54, 162, 235)',
      purple: 'rgb(153, 102, 255)',
      grey: 'rgb(201, 203, 207)',
    };

    this.state = {
      rendered: false,
      chartReference: undefined,
      chart: {
        labels: [],
        datasets: [
          {
            label: 'Media dei prezzi rispetto al prezzo minimo',
            backgroundColor: this.chartColors.blue,
          },
          {
            label: 'Media dei prezzi rispetto al prezzo medio',
            backgroundColor: this.chartColors.purple,
          },
          {
            label: 'Media dei prezzi rispetto al prezzo massimo',
            backgroundColor: this.chartColors.orange,
          },
        ],
      },

      options: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: 'Distribuzione dei prezzi rispetto alle Medie di Mercato Online',
        },
        legend: {
          position: 'bottom',
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        hover: {
          mode: 'nearest',
          intersect: true,
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: 'Giorno',
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: 'Percentuale',
              },
            },
          ],
        },
      },
    };
  }

  componentDidUpdate() {
    const { statsHistory } = this.props;
    const { chart, rendered } = this.state;

    if (!!!statsHistory.length || rendered) {
      return;
    }

    this.setState({ rendered: true });

    let sortStatsHistory = statsHistory.sort((a, b) => moment.utc(a.updated_at).diff(moment.utc(b.updated_at)));

    chart.labels = sortStatsHistory.map((x) => moment.utc(x.updated_at).local().format('DD/MM/YYYY h:mm'));
    console.log('sortStatsHistory', sortStatsHistory);

    chart.datasets[0].data = sortStatsHistory.map((x) => parseInt(x.avgpminprice));
    chart.datasets[1].data = sortStatsHistory.map((x) => parseInt(x.avgpavgprice));
    chart.datasets[2].data = sortStatsHistory.map((x) => parseInt(x.avgpmaxprice));

    this.setState({ chart });
  }

  render() {
    const { chart, options } = this.state;
    console.log('chart', chart);
    console.log('options', options);

    return (
      <div className="bgc-white bd bdrs-3 p-20 mB-20">
        <div className="chart-container" style={{ position: 'relative', height: '40vh', width: '80vw' }}>
          <Bar ref={(reference) => (this.chartReference = reference)} data={chart} options={options} height={80} />
        </div>
      </div>
    );
  }
}

export default AvgPriceChart;
