import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import Color from 'color';

import moment from 'moment';

import { couponService } from '../../_services/coupon.service';


export default class CouponChart extends Component {

  constructor(props) {
    super(props);

    this.chartColors = {
      red: 'rgb(255, 99, 132)',
      orange: 'rgb(255, 159, 64)',
      yellow: 'rgb(255, 205, 86)',
      green: 'rgb(75, 192, 192)',
      blue: 'rgb(54, 162, 235)',
      purple: 'rgb(153, 102, 255)',
      grey: 'rgb(201, 203, 207)'
    };

    this.state = {
      rendered: false,

      clicks: [],

      chart: {
        labels: [],
        datasets: [
          {
            label: 'Click',
            fill: false,
            hidden: false,
            borderColor: this.chartColors.blue,
            backgroundColor: Color(this.chartColors.blue).lighten(0.2).rgb(),
          },
        ],
      },

      options: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: 'Performance banner'
        },
        legend: {
          position: 'bottom'
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        hover: {
          mode: 'nearest',
          intersect: true
        },
        scales: {
          xAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Data'
            }
          }],
          yAxes: [{
            display: true,
            ticks: {
              min: 0,
              precision: 1,
              // stepSize: 1
            },
            scaleLabel: {
              display: true,
              labelString: 'Click'
            }
          }]
        }
      },
    };

    this.changeDates = this.changeDates.bind(this);

  }

  componentDidMount() {
    this.changeDates(this.props.from, this.props.to);
  }

  async changeDates() {
    if (!!this.props.user.id && !!this.props.shop.id && !!this.props.id) {

      let from = this.props.from;
      let to = this.props.to;

      let clicks = await couponService.clicks(this.props.user.id, this.props.shop.id, this.props.id, from.toISOString(), to.toISOString());

      let chart = this.state.chart;
      chart.datasets[0].data = [];

      let current = moment.utc(clicks.info.date.from).endOf('day');
      while (moment.utc(clicks.info.date.to).diff(current, 'day') >= 0) {
        chart.labels.push(moment(current).format('DD/MM/YYYY'));
        chart.datasets[0].data.push(0);
        current.add(1, 'day');
      }

      if (!!!clicks.data.length) {
        this.setState({ clicks: clicks.data });
        return;
      }

      let data = [];
      clicks.data.map(x => {
        let diff = moment.utc(clicks.info.date.to).diff(moment.utc(x.date).endOf('day'), 'day');
        data[chart.datasets[0].data.length - diff - 1] = x.clicks;
        return x;
      });

      window._.merge(chart.datasets[0].data, data);

      this.setState({ clicks: clicks.data });
    }
  }

  render() {
    return (
      <div className="chart-container" style={{ position: 'relative', height: '40vh', width: '80vw' }}>
        <Line
          ref={(reference) => this.chartReference = reference}
          data={this.state.chart}
          options={this.state.options}
          height={90}
        />
      </div>
    )
  }

}