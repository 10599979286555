import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import Color from 'color';

import moment from 'moment';

// let state = {};

class CountProdChart extends Component {
  constructor(props) {
    super(props);

    this.chartColors = {
      red: 'rgb(255, 99, 132)',
      orange: 'rgb(255, 159, 64)',
      yellow: 'rgb(255, 205, 86)',
      green: 'rgb(75, 192, 192)',
      blue: 'rgb(54, 162, 235)',
      purple: 'rgb(153, 102, 255)',
      grey: 'rgb(201, 203, 207)',
    };

    this.state = {
      rendered: false,

      chartReference: undefined,

      chart: {
        labels: [],
        datasets: [
          {
            label: 'Prodotti in prima posizione',
            fill: false,
            borderColor: this.chartColors.blue,
            backgroundColor: Color(this.chartColors.blue).lighten(0.2).rgb(),
          },
          {
            label: 'Tra 1% e 10% del prezzo minimo',
            fill: false,
            hidden: false,
            borderColor: this.chartColors.yellow,
            backgroundColor: Color(this.chartColors.yellow).lighten(0.2).rgb(),
          },
          {
            label: 'Tra 11% e 20% del prezzo minimo',
            fill: false,
            hidden: false,
            borderColor: this.chartColors.green,
            backgroundColor: Color(this.chartColors.green).lighten(0.2).rgb(),
          },
          {
            label: 'Prezzo vicino al prezzo medio',
            fill: false,
            hidden: false,
            borderColor: this.chartColors.orange,
            backgroundColor: Color(this.chartColors.orange).lighten(0.2).rgb(),
          },
          {
            label: 'Prezzo vicino al prezzo massimo',
            fill: false,
            hidden: false,
            borderColor: this.chartColors.red,
            backgroundColor: Color(this.chartColors.red).lighten(0.2).rgb(),
          },
        ],
      },

      options: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: 'Posizione prodotti',
        },
        legend: {
          position: 'bottom',
          // onClick: this.chartLabelAction,
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        hover: {
          mode: 'nearest',
          intersect: true,
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Giorno',
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Numero di prodotti',
              },
            },
          ],
        },
      },
    };

    this.chartLabelAction = this.chartLabelAction.bind(this);
  }

  componentDidUpdate() {
    if (!!!this.props.statsHistory.length || this.state.rendered) {
      return;
    }

    this.setState({ rendered: true });

    let chart = this.state.chart;
    let statsHistory = this.props.statsHistory.sort((a, b) => moment.utc(a.updated_at).diff(moment.utc(b.updated_at)));

    chart.labels = statsHistory.map((x) => moment.utc(x.updated_at).local().format('DD/MM/YYYY h:mm'));

    chart.datasets[0].data = statsHistory.map((x) => parseInt(x.countbest));
    chart.datasets[1].data = statsHistory.map((x) => parseInt(x.countaboutbest));
    chart.datasets[2].data = statsHistory.map((x) => parseInt(x.countaboutbest20));
    chart.datasets[3].data = statsHistory.map((x) => parseInt(x.countavg));
    chart.datasets[4].data = statsHistory.map((x) => parseInt(x.countmax));

    this.setState({ chart });
  }

  chartLabelAction(e, legendItem) { }

  render() {
    return (
      <div className="bgc-white bd bdrs-3 p-20 mB-20 ">
        <div className="chart-container" style={{ position: 'relative', height: '40vh', width: '80vw' }}>
          <Line
            ref={(reference) => (this.chartReference = reference)}
            data={this.state.chart}
            options={this.state.options}
          />
        </div>
      </div>
    );
  }
}

export default CountProdChart;
