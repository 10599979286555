import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import Color from 'color';

import moment from 'moment';

import { bannerService } from '../../_services/banner.service';
// import DataPicker from '../../_components/DataPicker';


export default class BannerChart extends Component {

  constructor(props) {
    super(props);

    this.chartColors = {
      red: 'rgb(255, 99, 132)',
      orange: 'rgb(255, 159, 64)',
      yellow: 'rgb(255, 205, 86)',
      green: 'rgb(75, 192, 192)',
      blue: 'rgb(54, 162, 235)',
      purple: 'rgb(153, 102, 255)',
      grey: 'rgb(201, 203, 207)'
    };

    this.state = {
      rendered: false,

      views: [],
      clicks: [],

      chart: {
        labels: [],
        datasets: [
          {
            label: 'Visualizzazioni',
            yAxisID: 'Views',
            fill: false,
            borderColor: this.chartColors.blue,
            backgroundColor: Color(this.chartColors.blue).lighten(0.2).rgb(),
          },
          {
            label: 'Click',
            yAxisID: 'Clicks',
            fill: false,
            hidden: false,
            borderColor: this.chartColors.yellow,
            backgroundColor: Color(this.chartColors.yellow).lighten(0.2).rgb(),
          },
        ],
      },

      options: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: 'Performance banner'
        },
        legend: {
          position: 'bottom'
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        hover: {
          mode: 'nearest',
          intersect: true
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Data'
              }
            },
          ],
          yAxes: [
            {
              id: 'Views',
              display: true,
              type: 'linear',
              ticks: {
                min: 0,
                beginAtZero: true,
                callback: function (value, index, values) {
                  if (Math.floor(value) === value) {
                    return value;
                  }
                }
              },
              scaleLabel: {
                display: true,
                labelString: 'Visualizzazioni'
              }
            },
            {
              id: 'Clicks',
              display: true,
              type: 'linear',
              position: 'right',
              ticks: {
                min: 0,
                beginAtZero: true,
                callback: function (value, index, values) {
                  if (Math.floor(value) === value) {
                    return value;
                  }
                }
              },
              scaleLabel: {
                display: true,
                labelString: 'Click'
              }
            },
          ]
        }
      },
    };

    this.changeDates = this.changeDates.bind(this);

  };

  componentDidMount() {
    this.changeDates(this.props.from, this.props.to);
  }

  async changeDates(from, to) {
    if (!!this.props.user.id && !!this.props.shop.id && !!this.props.id) {

      let [views, clicks] = await Promise.all([
        bannerService.views(this.props.user.id, this.props.shop.id, this.props.id, from.toISOString(), to.toISOString()),
        bannerService.clicks(this.props.user.id, this.props.shop.id, this.props.id, from.toISOString(), to.toISOString()),
      ]);



      let chart = this.state.chart;
      chart.labels = [];
      chart.datasets[0].data = [];
      chart.datasets[1].data = [];

      let current = moment.utc(views.info.date.from).endOf('day');
      while (moment.utc(views.info.date.to).diff(current, 'day') >= 0) {
        chart.labels.push(moment(current).format('DD/MM/YYYY'));
        chart.datasets[0].data.push(0);
        chart.datasets[1].data.push(0);
        current.add(1, 'day');
      }

      if (!!!views.data.length) {
        this.setState({ clicks: clicks.data });
        return;
      }

      let data = [];
      views.data.map(x => {
        let diff = moment.utc(views.info.date.to).diff(moment.utc(x.date).endOf('day'), 'day');
        data[chart.datasets[0].data.length - diff - 1] = x.views;
        return x;
      });

      window._.merge(chart.datasets[0].data, data);

      data = [];
      clicks.data.map(x => {
        let diff = moment.utc(clicks.info.date.to).diff(moment.utc(x.date).endOf('day'), 'day');
        data[chart.datasets[1].data.length - diff - 1] = x.clicks;
        return x;
      });

      window._.merge(chart.datasets[1].data, data);
      let clicksum = clicks.data.reduce((sum, value) => sum + value.clicks, 0);
      let viewsum = views.data.reduce((sum, value) => sum + value.views, 0);

      this.setState({ views: views.data, clicks: clicks.data, clicksum: clicksum, viewsum: viewsum });
    }
  }

  render() {
    return (
      <div>
        <div>Questo banner ha ricevuto {this.state.clicksum} click, {this.state.viewsum} visualizzazioni nel periodo selezionato.</div>
        <div className="chart-container" style={{ position: 'relative' }}>
          <Line
            ref={(reference) => this.chartReference = reference}
            data={this.state.chart}
            options={this.state.options}
            height={100}
          />
        </div>
      </div >
    )
  }

}