import React, { Component } from 'react';

import { Pie, Bar } from 'react-chartjs-2';

import { dataService } from '../../../../_services/data.service';

export default class ChartsCategory extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.chartColors = {
      red: 'rgb(255, 99, 132)',
      orange: 'rgb(255, 159, 64)',
      yellow: 'rgb(255, 205, 86)',
      green: 'rgb(75, 192, 192)',
      blue: 'rgb(54, 162, 235)',
      purple: 'rgb(153, 102, 255)',
      grey: 'rgb(201, 203, 207)',
    };

    this.state = {
      dataInterests: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
            hoverBackgroundColor: [],
          },
        ],
      },

      dataDiscount: {
        labels: [],
        datasets: [
          {
            label: '% Sconto applicato sulla categoria',
            data: [],
            backgroundColor: [],
            hoverBackgroundColor: [],
          },
        ],
      },
      dataManufactorI: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
            hoverBackgroundColor: [],
          },
        ],
      },

      dataManufactorD: {
        labels: [],
        datasets: [
          {
            label: '% Sconto applicato sulla ditta',
            data: [],
            backgroundColor: [],
            hoverBackgroundColor: [],
          },
        ],
      },
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    let dataInterests = this.state.dataInterests;
    let dataDiscount = this.state.dataDiscount;
    let dataManufactorI = this.state.dataManufactorI;
    let dataManufactorD = this.state.dataManufactorD;

    let [categoryInterests, categoryDiscount, manufactorInterests, manufactorDiscount] = [
      await dataService.getCategoryInterests(),
      await dataService.getCategoryDiscount(),
      await dataService.getManufactorInterests(),
      await dataService.getManufactorDiscount(),
    ];

    // Controlla se i valori sono array, altrimenti inizializza come array vuoto
    categoryInterests = Array.isArray(categoryInterests) ? categoryInterests : [];
    categoryDiscount = Array.isArray(categoryDiscount) ? categoryDiscount : [];
    manufactorInterests = Array.isArray(manufactorInterests) ? manufactorInterests : [];
    manufactorDiscount = Array.isArray(manufactorDiscount) ? manufactorDiscount : [];

    let getColor = (k) =>
      !!Object.values(this.chartColors)[k]
        ? Object.values(this.chartColors)[k]
        : `rgb(${getRandomInt(0, 255)}, ${getRandomInt(0, 255)}, ${getRandomInt(0, 255)})`;

    [
      dataInterests.labels,
      dataDiscount.labels,
      dataManufactorI.labels,
      dataManufactorD.labels,
      dataInterests.datasets[0].data,
      dataDiscount.datasets[0].data,
      dataManufactorI.datasets[0].data,
      dataManufactorD.datasets[0].data,
      dataInterests.datasets[0].backgroundColor,
      dataDiscount.datasets[0].backgroundColor,
      dataManufactorI.datasets[0].backgroundColor,
      dataManufactorD.datasets[0].backgroundColor,
    ] = [
        categoryInterests.map((x) => x.categoria.charAt(0).toUpperCase() + x.categoria.slice(1)),
        categoryDiscount.map((x) => x.categoria.charAt(0).toUpperCase() + x.categoria.slice(1)),
        manufactorInterests.map((x) => x.ditta.charAt(0).toUpperCase() + x.ditta.slice(1)),
        manufactorDiscount.map((x) => x.ditta.charAt(0).toUpperCase() + x.ditta.slice(1)),
        categoryInterests.map((x) => parseFloat(x.totale).toFixed(2)),
        categoryDiscount.map((x) => parseFloat(x.totale).toFixed(2)),
        manufactorInterests.map((x) => parseFloat(x.totale).toFixed(2)),
        manufactorDiscount.map((x) => parseFloat(x.totale).toFixed(2)),
        categoryDiscount.map((x, k) => getColor(k)),
        categoryDiscount.map((x, k) => getColor(k)),
        categoryDiscount.map((x, k) => getColor(k)),
        categoryDiscount.map((x, k) => getColor(k)),
      ];

    if (!!this._isMounted) {
      this.setState({
        dataInterests,
        dataDiscount,
        dataManufactorI,
        dataManufactorD,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeLoader();
  }

  render() {
    return (
      <>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title text-muted">Interesse per categoria</h4>
                <div className="chart-container" style={{ position: 'relative', height: '40vh', width: '80vw' }}>
                  <Pie
                    data={this.state.dataInterests}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      legend: {
                        position: 'bottom',
                      },
                    }}
                  /></div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title text-muted">Interesse per Ditta</h4>
                <div className="chart-container" style={{ position: 'relative', height: '40vh', width: '80vw' }}>
                  <Pie
                    data={this.state.dataManufactorI}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      legend: {
                        position: 'bottom',
                      },
                    }}
                  /></div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mT-20">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title text-muted">Sconto medio applicato alla categoria</h4>
                <div className="chart-container" style={{ position: 'relative', height: '40vh', width: '80vw' }}>
                  <Bar
                    data={this.state.dataDiscount}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      legend: {
                        position: 'none',
                      },
                    }}
                  /></div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title text-muted">Sconto medio applicato alla Ditta</h4>
                <div className="chart-container" style={{ position: 'relative', height: '40vh', width: '80vw' }}>
                  <Bar
                    data={this.state.dataManufactorD}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      legend: {
                        position: 'bottom',
                      },
                    }}
                  /></div>
              </div>
            </div>
          </div>

          <div className="col-12 mT-20 small muted">
            Dati calcolati sull'interesse dei visitatori su prezzifarmaco.it e sui prezzi applicati rispetto ai prezzi
            massimi o di listino negli ultimi 30 giorni, aggiornato ogni 24 ore.
          </div>
        </div>
      </>
    );
  }
}

function getRandomInt(min, max) {
  max++;
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}
