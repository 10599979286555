import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';

import { analyticsService } from '../../../_services/analytics.service';
import { Doughnut } from 'react-chartjs-2';
//https://github.com/jerairrest/react-chartjs-2/blob/master/example/src/components/polar.js

export default class ECommerceProducts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stats: undefined,
      totclick: 0,
      priceClickStatsData: [],
      dataanalisiCatalogo: {},
      optionsanalisiCatalogo: {},
      datadistribuzionePrezzi: {},
      optionsdistribuzionePrezzi: {},
      dataPrezziProdotti: {},
      optionsPrezziProdotti: {},
    };
  }

  async componentDidMount() {
    if (!!this.props.user.id && !!this.props.shop.id) {
      try {
        const stats = await analyticsService.stats(this.props.user.id, this.props.shop.id);
        this.setState({ stats });

        await this.analisiCatalogo();
        await this.prezziProdotti();
        await this.distribuzionePrezzi();
      } catch (error) {
        console.error(error);
      }
    }

    window.removeLoader();
  }

  async analisiCatalogo() {
    let dataanalisiCatalogo = {
      labels: [
        '1. Prima posizione',
        '2. Tra 1% e 10% del prezzo minimo',
        '3. Tra 11% e 20% del prezzo minimo',
        '4. Prezzo Medio',
        '5. Prezzo Massimo',
      ],
      datasets: [
        {
          label: 'Distribuzione del Catalogo rispetto alla Media dei Prezzi di Mercato',
          backgroundColor: [
            'rgba(75, 192, 130, 0.5)',
            'rgb(190, 229, 235, 0.5)',
            'rgba(150, 150, 150, 0.5)',
            'rgb(255, 193, 7, 0.5)',
            'rgba(255, 99, 132, 0.5)',
          ],
          borderColor: [
            'rgba(75, 192, 130, 1)',
            'rgb(190, 229, 235, 1)',
            'rgba(150, 150, 150, 1)',
            'rgb(255, 193, 7, 1)',
            'rgba(255, 99, 132, 1)',
          ],

          borderWidth: 1,
          data: [
            this.state.stats.countbest,
            this.state.stats.countaboutbest,
            this.state.stats.countaboutbest20,
            this.state.stats.countavg,
            this.state.stats.countmax,
          ],
        },
      ],
    };

    this.setState({ dataanalisiCatalogo });

    let optionsanalisiCatalogo = {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'right',
      },
    };

    this.setState({ optionsanalisiCatalogo });
  }

  async distribuzionePrezzi() {
    const priceClickStats = await analyticsService.priceClickStats(this.props.user.id, this.props.shop.id);

    this.setState({ totclick: priceClickStats.total });

    var lab = {};
    Object.keys(priceClickStats.data)
      .sort((a, b) => a.split('_').pop() - b.split('_').pop())
      .map((k) => (lab[k] = priceClickStats.data[k]));

    let labels = [];
    let priceClickStatsData = [];

    for (var l in lab) {
      if (l.split('_')[1] === '9999999999') {
        labels.push('Prezzo oltre ' + l.split('_')[0] + '€ ');

        priceClickStatsData.push(
          `Il ${Math.round(
            (lab[l] / priceClickStats.total) * 100
          )}% dei click sono su prodotti con prezzo superiore a ${l.split('_')[0]}€ `
        );
      } else if (l.split('_')[0] === '0') {
        labels.push('Prezzo fino a ' + l.split('_')[1] + '€ ');

        priceClickStatsData.push(
          `Il ${Math.round(
            (lab[l] / priceClickStats.total) * 100
          )}% dei click sono su prodotti con prezzo inferiore a ${l.split('_')[1]}€`
        );
      } else {
        labels.push('Prezzo tra ' + l.split('_')[0] + '€ e ' + l.split('_')[1] + '€ ');

        priceClickStatsData.push(
          `Il ${Math.round((lab[l] / priceClickStats.total) * 100)}% dei click sono su prodotti con prezzo tra ${l.split('_')[0]
          }€ e ${l.split('_')[1]}€`
        );
      }
    }

    await this.setState({ priceClickStatsData: priceClickStatsData });

    let dat = Object.values(priceClickStats.data);

    let datadistribuzionePrezzi = {
      labels: labels,
      datasets: [
        {
          label: 'Distribuzione dei click ricevuti negli ultimi 30 giorni in base al prezzo di vendita del prodotto',
          backgroundColor: [
            'rgba(75, 192, 130, 0.5)',
            'rgb(190, 229, 235, 0.5)',
            'rgb(255, 193, 7, 0.5)',
            'rgba(255, 99, 132, 0.5)',
            'rgba(97, 255, 221, 0.5)',
            'rgba(97, 171, 255, 0.5)',
            'rgba(102, 97, 255, 0.5)',
            'rgba(150, 150, 150, 0.5)',
          ],
          borderColor: [
            'rgba(75, 192, 130, 1)',
            'rgb(190, 229, 235, 1)',
            'rgb(255, 193, 7, 1)',
            'rgba(255, 99, 132, 1)',
            'rgba(97, 255, 221, 1)',
            'rgba(97, 171, 255, 1)',
            'rgba(102, 97, 255, 1)',
            'rgba(150, 150, 150, 1)',
          ],
          borderWidth: 1,
          data: dat,
        },
      ],
    };

    this.setState({ datadistribuzionePrezzi });

    let optionsdistribuzionePrezzi = {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'right',
      },
    };

    this.setState({ optionsdistribuzionePrezzi });
  }

  async prezziProdotti() {
    let dataPrezziProdotti = {
      labels: ['1. Rispetto al prezzo Minimo', '2. Rispetto al prezzo Medio', '3. Rispetto al prezzo Massimo'],
      datasets: [
        {
          label: 'Analisi Catalogo',
          backgroundColor: ['rgba(54, 162, 235, 0.5)', 'rgba(54, 54, 235, 0.5)', 'rgba(153, 102, 255, 0.5)'],
          borderColor: ['rgba(54, 162, 235, 1)', 'rgba(54, 54, 235, 1)', 'rgba(153, 102, 255, 1)'],
          borderWidth: 1,
          // data: [data.avgpminprice, data.avgpavgprice, data.avgpmaxprice],
          data: [
            this.state.stats.avgpminprice > 0 ? this.state.stats.avgpminprice : -this.state.stats.avgpminprice,
            this.state.stats.avgpavgprice > 0 ? this.state.stats.avgpavgprice : -this.state.stats.avgpavgprice,
            this.state.stats.avgpmaxprice > 0 ? this.state.stats.avgpmaxprice : -this.state.stats.avgpmaxprice,
          ],
        },
      ],
    };

    this.setState({ dataPrezziProdotti });

    let optionsPrezziProdotti = {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'right',
      },
      tooltips: {
        // Disable the on-canvas tooltip
        enabled: true,
      },
    };

    this.setState({ optionsPrezziProdotti });
  }

  render() {
    const { user, shop } = this.props;
    const { stats } = this.state;

    const priceClickStatsDataList = this.state.priceClickStatsData.map(function (d, i) {
      return (
        <span key={i}>
          {d}
          <br />
        </span>
      );
    });

    return !user.id || !shop.id ? (
      <Alert color="warning">
        Non hai ancora un negozio associato. <Link to="/shop">Vai al form di creazione.</Link>
      </Alert>
    ) : (
      <div>
        {!!!stats ? (
          <div className="row">
            <div className="col">
              <i className="float-left fas fa-spinner fa-spin" />
              <br />
              <p>
                <b>Elaborazione dei dati in corso.</b>
              </p>
              <p>L'operazione potrebbe richiedere alcuni minuti!</p>
              <p>
                Il sistema sta processando in background la tua richiesta, torna più tardi per visualizzare i grafici!
              </p>
            </div>
          </div>
        ) : (
          <div>
            <div className="row">
              <div className="col-md-6 mT-20">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title text-muted">
                      Distribuzione del Catalogo rispetto alla Media dei Prezzi di Mercato
                    </h5>
                    <div className="chart-container" style={{ position: 'relative', }}>
                      <Doughnut data={this.state.dataanalisiCatalogo} options={this.state.optionsanalisiCatalogo} />
                    </div>
                    <h6>I dati visualizzati sono calcolati su {stats.totprod} prodotti totali nel catalogo</h6>
                    <p>
                      Il {Math.round((stats.countbest / stats.totprod) * 100)}% del catalogo ( {stats.countbest}{' '}
                      prodotti ) hanno il <span className="badge alert-success">prezzo più basso</span>
                      <br />
                      Il {Math.round((stats.countaboutbest / stats.totprod) * 100)}% del catalogo ({' '}
                      {stats.countaboutbest} prodotti ) hanno il prezzo tra 1% e 10% del{' '}
                      <span className="badge alert-info">prezzo minimo</span>
                      <br />
                      Il {Math.round((stats.countaboutbest20 / stats.totprod) * 100)}% del catalogo ({' '}
                      {stats.countaboutbest20} prodotti ) hanno il prezzo tra 11% e 20% del{' '}
                      <span className="badge alert-info">prezzo minimo</span>
                      <br />
                      Il {Math.round((stats.countavg / stats.totprod) * 100)}% del catalogo ( {stats.countavg} prodotti
                      ) hanno il prezzo vicino al <span className="badge alert-warning">prezzo medio</span>
                      <br />
                      Il {Math.round((stats.countmax / stats.totprod) * 100)}% del catalogo ( {stats.countmax} prodotti
                      ) hanno il prezzo vicino <span className="badge alert-danger">prezzo massimo</span>
                      <br />
                    </p>
                    <p>Per prezzo minimo/medio/massimo si intende il prezzo minimo/medio/massimo del Mercato Online.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mT-20">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title text-muted">
                      Distribuzione dei prezzi rispetto alle Medie di Mercato Online
                    </h5>
                    <h6>La media dei prezzi è calcolata su {stats.totprod} prodotti totali nel catalogo</h6>
                    <p>In media i prezzi di tutti i prodotti sono:</p>
                    <p className="alert">
                      <i
                        className={
                          stats.avgpminprice < 20
                            ? 'float-left fas fa-3x text-success fa-thumbs-up'
                            : 'float-left fas fa-3x text-danger fa-thumbs-down'
                        }
                      ></i>
                      &nbsp; più <b>{stats.avgpminprice > 0 ? 'Alti' : 'Bassi'}</b> del {stats.avgpminprice}% rispetto
                      al prezzo <span className="badge alert-success">minimo</span>
                      <br />
                      {stats.avgpminprice < 20
                        ? `Il pricing del tuo ecommerce è buono, in media i tuoi prodotti costano solo il ${stats.avgpminprice} del prezzo minimo.`
                        : `Prendi in considerazione un'analisi approfondita del pricing dei prodotti nel tuo e-commerce.`}
                      &nbsp;Rimanere sotto il 20% aumenta la probabilità di essere posizionato nella prima pagina di
                      risultati nei comparatori di prezzi.
                    </p>
                    <p className="alert">
                      <i
                        className={
                          stats.avgpavgprice < 5
                            ? 'float-left fas fa-3x text-success fa-thumbs-up'
                            : 'float-left fas fa-3x text-danger fa-thumbs-down'
                        }
                      ></i>
                      &nbsp; più <b>{stats.avgpavgprice > 0 ? 'Alti' : 'Bassi'}</b> del {stats.avgpavgprice}% rispetto
                      al prezzo <span className="badge alert-warning">medio</span>
                      <br />
                      {stats.avgpavgprice < 5
                        ? `Il pricing del tuo ecommerce è vicino al prezzo medio, in generale questo indica che la tua strategia di pricing è accurata.`
                        : `Il pricing del tuo ecommerce è oltre il prezzo medio, in generale questo indica che la tua strategia di pricing va analizzata con attenzione.`}
                      &nbsp;Rimanere al di sotto del prezzo medio consente ad una buona parte dei prodotti del tuo
                      catalogo di essere ben presenti e visibili.
                    </p>
                    <p className="alert">
                      <i
                        className={
                          stats.avgpmaxprice < -20
                            ? 'float-left fas fa-3x text-success fa-thumbs-up'
                            : 'float-left fas fa-3x text-danger fa-thumbs-down'
                        }
                      ></i>
                      &nbsp; più <b>{stats.avgpmaxprice > 0 ? 'Alti' : 'Bassi'}</b> del {stats.avgpmaxprice}% rispetto
                      al prezzo <span className="badge alert-danger">massimo</span>
                      <br />
                      {stats.avgpmaxprice < -20
                        ? `Il pricing del tuo ecommerce è londano dal prezzo massimo, i tuoi prodotti mostrano un'etichetta con una % di sconto applicato sul prezzo di listino superiore al 20%.`
                        : `Il pricing del tuo ecommerce è vicino al prezzo massimo, i tuoi prodotti raramente mostrano un'etichetta con una % di sconto.`}
                      &nbsp;Tipicamente il prezzo massimo è allineato col prezzo di listino. Più il tuo catalogo in
                      media è lontano dal prezzo massimo, maggiori saranno le probabilità di attrarre clienti.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mT-20">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title text-muted">
                      Distribuzione dei click ricevuti negli ultimi 30 giorni in base al prezzo di vendita del prodotto
                    </h5>
                    <div className="chart-container" style={{ position: 'relative', }}>
                      <Doughnut
                        data={this.state.datadistribuzionePrezzi}
                        options={this.state.optionsdistribuzionePrezzi}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mT-20">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title text-muted">
                      Distribuzione dei click ricevuti negli ultimi 30 giorni in base al prezzo di vendita del prodotto
                    </h5>
                    <h6>
                      I dati visualizzati sono calcolati su {this.state.totclick} click ricevuti negli ultimi 30 giorni
                    </h6>

                    <p>{priceClickStatsDataList}</p>

                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mT-20">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title text-muted">Confronto delle Spese di spedizione</h5>
                    <div className="row">
                      <div className="col">
                        <div className="col-10">
                          <div className="speseSpedizione onlight slider lj-slider">
                            <div className="pinCnt" style={{ left: '0%' }}>
                              <div className="pin a0" data-val="0">
                                <div className="speseSpedizione toolTip a0">
                                  <p>
                                    <span className="a0" style={{ width: '10px' }}></span>
                                    La spedizione più Bassa è di <b>{stats.minsped}€</b>!
                                  </p>
                                </div>
                              </div>
                              <div className="pinLabel">{stats.minsped}€</div>
                            </div>
                            <div
                              className="pinCnt"
                              style={{
                                left: (stats.avgsped / stats.maxsped) * 100 + '%',
                              }}
                            >
                              <div className="pin a1" data-val="5">
                                <div className="speseSpedizione toolTip a1">
                                  <p>
                                    <span className="a1" style={{ width: '10px' }}></span>
                                    In media il costo della spesa di spedizione è <b>{stats.avgsped}€</b>!
                                  </p>
                                </div>
                              </div>
                              <div className="pinLabel">{stats.avgsped}€</div>
                            </div>
                            <div
                              className="pinCnt yours"
                              style={{
                                left: (stats.costo_spedizione / stats.maxsped) * 100 + '%',
                              }}
                            >
                              <div className="pin a2">
                                <div className="speseSpedizione toolTip a2">
                                  <p>
                                    <span className="a2" style={{ width: '10px' }}></span>
                                    Il costo della TUA spedizione è <b>{stats.costo_spedizione}€</b>!
                                  </p>
                                </div>
                              </div>
                              <div className="pinLabel">
                                <i className="fa fa-arrow-up" aria-hidden="true"></i> {stats.costo_spedizione}€
                              </div>
                            </div>
                            <div className="pinCnt" style={{ left: '100%' }}>
                              <div className="pin a3" data-val="8">
                                <div className="speseSpedizione toolTip a3">
                                  <p>
                                    <span className="a3" style={{ width: '10px' }}></span>
                                    La spedizione più alta è di <b>{stats.maxsped}€</b>!
                                  </p>
                                </div>
                              </div>
                              <div className="pinLabel">{stats.maxsped}€</div>
                            </div>
                          </div>
                          <div className="wrapLeggenda">
                            <p className="leggenda">
                              <span className="a0"></span>1. La spedizione più Bassa
                            </p>
                            <p className="leggenda">
                              <span className="a1"></span>2. Media del costo di spedizione
                            </p>
                            <p className="leggenda">
                              <span className="a2"></span>3. La <b>TUA</b> spedizione{' '}
                            </p>
                            <p className="leggenda">
                              <span className="a3"></span>4. La spedizione più Alta
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mT-20">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title text-muted">Confronto della soglia per la spedizione gratuita</h5>
                    <div className="row">
                      <div className="col">
                        <div className="col-10">
                          <div className="speseSpedizione onlight slider lj-slider">
                            <div className="pinCnt" style={{ left: '0%' }}>
                              <div className="pin a0" data-val="0">
                                <div className="speseSpedizione toolTip a0">
                                  <p>
                                    <span className="a0" style={{ width: '10px' }}></span>
                                    La spedizione più Bassa è di <b>{stats.minspedfree}€</b>!
                                  </p>
                                </div>
                              </div>
                              <div className="pinLabel">{stats.minspedfree}€</div>
                            </div>
                            <div
                              className="pinCnt"
                              style={{
                                left: (stats.avgspedfree / stats.maxspedfree) * 100 + '%',
                              }}
                            >
                              <div className="pin a1" data-val="5">
                                <div className="speseSpedizione toolTip a1">
                                  <p>
                                    <span className="a1" style={{ width: '10px' }}></span>
                                    In media il costo della spesa di spedizione è <b>{stats.avgspedfree}€</b>!
                                  </p>
                                </div>
                              </div>
                              <div className="pinLabel">{stats.avgspedfree}€</div>
                            </div>
                            <div
                              className="pinCnt yours"
                              style={{
                                left: (stats.spedizione_gratuita_oltre / stats.maxspedfree) * 100 + '%',
                              }}
                            >
                              <div className="pin a2">
                                <div className="speseSpedizione toolTip a2">
                                  <p>
                                    <span className="a2" style={{ width: '10px' }}></span>
                                    La <b>TUA</b> soglia di spedizione gratuita{' '}
                                    <b>{stats.spedizione_gratuita_oltre}€</b>!
                                  </p>
                                </div>
                              </div>
                              <div className="pinLabel">
                                <i className="fa fa-arrow-up" aria-hidden="true"></i> {stats.spedizione_gratuita_oltre}€
                              </div>
                            </div>
                            <div className="pinCnt" style={{ left: '100%' }}>
                              <div className="pin a3" data-val="8">
                                <div className="speseSpedizione toolTip a3">
                                  <p>
                                    <span className="a3" style={{ width: '10px' }}></span>
                                    La spedizione più alta è di <b>{stats.maxspedfree}€</b>!
                                  </p>
                                </div>
                              </div>
                              <div className="pinLabel">{stats.maxspedfree}€</div>
                            </div>
                          </div>
                          <div className="wrapLeggenda">
                            <p className="leggenda">
                              <span className="a0"></span>1. Soglia minima per le spedizioni gratuite
                            </p>
                            <p className="leggenda">
                              <span className="a1"></span>2. Soglia media per le spedizioni gratuite{' '}
                            </p>
                            <p className="leggenda">
                              <span className="a2"></span>3. La <b>TUA</b> soglia di spedizione gratuita
                            </p>
                            <p className="leggenda">
                              <span className="a3"></span>4. Soglia massima per le spedizioni gratuite
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
        }
      </div>
    );
  }
}
